//Configure your project's core colour palette here
// ================================================

$color-body: #000410;
$color-background: #FFF;
$color-background-alt: #000000;

$color-accent-primary: var(--color-accent-primary-themed);
$color-accent-secondary: #0B1E5D;

$color-accent-primary-default: var(--color-buttons-accent-default);
$color-boto: var(--color-buttons-accent);
$color-bg-accent: var(--color-background-accent);
$color-hotel-primary: var(--color-hotel-prim);
$color-buttons: var(--color-buttons);
$color-buttons-image: var(--color-buttons-img);
$color-buttons-txt: var(--color-buttons-text);
$color-hotel-secondary: #A7A09F;

$color-tertiary: #AFB5B2;

$color-success: #62994E;
$color-warning: #EEBE43;
$color-error: #CF3C49;

// Define your standard colour variables here
// ================================================

$color-vars: (
  body: $color-body,
  body-75: rgba($color-body, 0.75),
  body-50: rgba($color-body, 0.50),
  body-25: rgba($color-body, 0.25),
  body-10: rgba($color-body, 0.10),
  body-05: rgba($color-body, 0.05),
  body-01: rgba($color-body, 0.01),

  background: $color-background,
  background-75: rgba($color-background, 0.75),
  background-50: rgba($color-background, 0.5),
  background-25: rgba($color-background, 0.25),
  background-10: rgba($color-background, 0.10),
  background-05: rgba($color-background, 0.05),
  background-01: rgba($color-background, 0.01),

  hotel-primary: $color-hotel-primary,

  background-alt: $color-background-alt,

  button-accent-default: $color-accent-primary-default,

  background-acct: $color-bg-accent,
  accent-primary: $color-accent-primary,
  accent-primary-lightest: color-mix(in SRGB, $color-accent-primary 25%, #FFF),
  accent-primary-lighter: color-mix(in SRGB, $color-accent-primary 50%, #FFF),
  accent-primary-light: color-mix(in SRGB, $color-accent-primary 87.5%, #FFF),
  accent-primary-dark: color-mix(in SRGB, $color-accent-primary 87.5%, #000),
  accent-primary-darker: color-mix(in SRGB, $color-accent-primary 50%, #000),
  accent-primary-darkest: color-mix(in SRGB, $color-accent-primary 25%, #000),

  accent-secondary: $color-accent-secondary,
  accent-secondary-lightest: mix($color-accent-secondary, #FFF, 25%),
  accent-secondary-lighter: mix($color-accent-secondary, #FFF, 50%),
  accent-secondary-light: mix($color-accent-secondary, #FFF, 87.5%),
  accent-secondary-dark: mix($color-accent-secondary, #000, 87.5%),
  accent-secondary-darker: mix($color-accent-secondary, #000, 50%),
  accent-secondary-darkest: mix($color-accent-secondary, #000, 25%),

  accent-tertiary: $color-tertiary,

  hotel-secondary: $color-hotel-secondary,
  hotel-secondary-lightest: mix($color-hotel-secondary, #FFF, 25%),
  hotel-secondary-lighter: mix($color-hotel-secondary, #FFF, 50%),
  hotel-secondary-light: mix($color-hotel-secondary, #FFF, 87.5%),
  hotel-secondary-dark: mix($color-hotel-secondary, #000, 87.5%),
  hotel-secondary-darker: mix($color-hotel-secondary, #000, 50%),
  hotel-secondary-darkest: mix($color-hotel-secondary, #000, 25%),

  accent-boto: $color-boto,
  accent-reverse: $color-background,
  accent-butto: $color-buttons,
  accent-button: $color-buttons-txt,
  accent-reverse-alt: $color-body,

  success: $color-success,
  success-lightest: mix($color-success, #FFF, 25%),
  success-lighter: mix($color-success, #FFF, 50%),
  success-light: mix($color-success, #FFF, 87.5%),
  success-dark: mix($color-success, #000, 87.5%),
  success-darker: mix($color-success, #000, 50%),
  success-darkest: mix($color-success, #000, 25%),

  warning: $color-warning,
  warning-lightest: mix($color-warning, #FFF, 25%),
  warning-lighter: mix($color-warning, #FFF, 50%),
  warning-light: mix($color-warning, #FFF, 87.5%),
  warning-dark: mix($color-warning, #000, 87.5%),
  warning-darker: mix($color-warning, #000, 50%),
  warning-darkest: mix($color-warning, #000, 25%),

  error: $color-error,
  error-lightest: mix($color-error, #FFF, 25%),
  error-lighter: mix($color-error, #FFF, 50%),
  error-light: mix($color-error, #FFF, 87.5%),
  error-dark: mix($color-error, #000, 87.5%),
  error-darker: mix($color-error, #000, 50%),
  error-darkest: mix($color-error, #000, 25%),
);

// A mixin that gives you an appropriate fallback for browsers that don't support CSS variables (basically just IE)
// Usage example:
//  @include color(background-color, 'accent-primary', #333);
// outputs:
//  background-color: #333;
//  background-color: var(--accent-primary, #333);
//
// Tip: You don't need to include a fallback variable for most cases – you only need to use it when your desired
//      IE fallback isn't the standard default value (e.g. setting text colour over an image background)

@mixin color($property, $color, $fallback: null) {
    @if $fallback {
        #{$property}: #{$fallback};
        #{$property}: var(--color-#{$color}, #{$fallback});
    } @else {
        #{$property}: map-deep-get($color-vars, $color);
        #{$property}: var(--color-#{$color}, map-get($color-vars, $color));
    }
}