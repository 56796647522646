// Fonts for UP Core Vogue
$font-stack-headings: 'Caslons Egyptian Med', Bahnschrift, 'DIN Alternate', 'Franklin Gothic Medium', 'Nimbus Sans Narrow', sans-serif-condensed, sans-serif;
$font-stack-primary: 'Caslons Egyptian Bk', Bahnschrift, 'DIN Alternate', 'Franklin Gothic Medium', 'Nimbus Sans Narrow', sans-serif-condensed, sans-serif;

// General Breakpoints
$breakpoints: (
    'XXS': ( max-width:  30em ),
    'XS': ( max-width:  40em ),
    'XS-up': ( min-width:  641px ),
    'S': ( max-width:  48em ),
    'S-up': ( min-width: 769px ),
    'M': ( max-width:  64em ),
    'M-up': ( min-width:  64em ),
    'ML': ( max-width:  75em ),
    'ML-up': ( min-width:  75em ),
    'L': ( max-width: 91em ),
    'XL': ( min-width: 91em ),
    'XXL': ( min-width: 105em ),
    'XXXL': ( min-width: 112em )
);
