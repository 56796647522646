.wp-block-table {
    max-width: 52rem;
    margin-left: auto;
    margin-right: auto;

    &.is-style-stripes {
        border: 0;

        th,
        td {
            @include color(border-color, 'body-10');
        }
    }
}