.footer {
    padding-top: 4rem;
    padding-bottom: 3rem;
    border-top: 1px solid;
    border-bottom: 1px solid;
    position: relative;
    @include color(border-color, 'body');
    overflow-y: hidden;

    div.footer-title{
        @include subtitle-2;
        font-family: $font-stack-primary;
        padding-bottom: 1.5rem;

        @include breakpoint(M) {
            padding-top: 1rem;
        }
    }

    .footer-contact{
        text-align: center;
        width: 100%;
    }
    .footer-social{
        a.button{
            border: 0;
        }
    }
    .social-links {
        width: 5%;
        flex-shrink: 0;

        @include breakpoint(XS) {
            width: 100%;
        }
        .button {
            margin: 0 0 1rem 0;

            @include breakpoint(XS) {
                margin: 0 0.5rem;
            }
        }
    }
    .newsletter-footer{
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        @include color(background-color, 'background');
        top: 150%;
        right: 0;
        z-index: 1;
        // opacity: 0;
        // visibility: hidden;
            animation: fadeIn 1s;
            -webkit-animation: fadeIn 1s;
            -moz-animation: fadeIn 1s;
            -o-animation: fadeIn 1s;
            -ms-animation: fadeIn 1s;
        &.active{
            // opacity: 1;
            // visibility: visible;
            display: block;
            right: 0;
            top: 0;
            animation: fadeOut 1s;
            -webkit-animation: fadeOut 1s;
            -moz-animation: fadeOut 1s;
            -o-animation: fadeOut 1s;
            -ms-animation: fadeOut 1s;
        }
        @keyframes fadeIn {
            from {top: 0;}
            to {top:  100%;}
        }
        @keyframes fadeOut {
            from {top: 100%;}
            to {top: 0;}
        }
        .nav-close-newsletter{
            position: absolute;
            top: 1.5rem;
            right: 1.5rem;
        }
        .full-newsletter{
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-self: center;
            width: 100%;
            header {
                width: 100%;
            }
            .newsletter-container {
                width: 100%;
                display: contents;
            }
            label {
                cursor: pointer;
                font-weight: 400;
                font-family: $font-stack-primary;
                width: 100%;
                margin-right: 1rem;
            }
            label.input-name{
                @include size-S;
                @include color(color, 'body-50');
            }
            .newsletter {
                width: 100%;
                @include color(background-color, 'background');
            
                input {
                    flex-grow: 1;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border: 0;
                    border-bottom: 1px solid;
                   @include color(border-color, 'body');
                   margin-right: 2rem;
                   padding-left: 0;
                   margin-top: 0;
                   padding-top: 0.3rem;
                }
                button {
                    position: relative;
                    z-index: 2;
                    margin-left: 0;
                    flex-shrink: 0;
            
                    @include breakpoint(S) {
                        padding: 0.75rem;
                    }
                }
            }
            .gr_simple_horizontal_wrapper{
                form{
                    .gform_body.gform-body{
                        label{
                            @include size-S;
                            @include color(color, 'body-50');
                        }
                        input[type="text"],
                        input[type="email"]{
                            border: none;
                            border-bottom: 1px solid;
                            @include color(border-color, 'body');
                        }
                        #honeyMonster {
                            display: none;
                            visibility: hidden;
                            opacity: 0;
                        }
                        .gfield_html{
                            display: flex;
                            align-items: end;
                            div{
                                width: 100%;
                                button{
                                    width: 100%;
                                }
                            }
                        }
                        .gchoice{
                            display: flex;
                        }
                    }
                    .gform_footer{
                        opacity: 0;
                        visibility: hidden;
                        position: absolute;
                        left: -100vw;
                    }
                }
            }
        }

        @include breakpoint(M) {
            header {
                text-align: center;
            }
            label.input-name{
                margin: 1rem 0;
            }
            .newsletter{
                flex-direction: column;
                max-width: 30rem;
                margin: auto;
                button {
                    margin: 1rem 0;
                }
            }
            .accept{
                max-width: 30rem;
                margin: auto;
            }
        }
    }
    @include breakpoint(M) {
        flex-direction: column;
    }
}
.footer-container{
    flex-basis: 33.33%;
    padding: 0 2rem;
    p{
        @include body-S;
    }
    .join-nl{
        cursor: pointer;
        &:hover{
            @extend %transition;
            @include color(color, 'hotel-primary');
        }
    }
    .tel,
    .email{
        a{
            @include color(color, 'body');
            @extend %transition;
            &:hover{
                @extend %transition;
                @include color(color, 'hotel-primary');
            }
        }
    }
}
.footer-logo {

    @include breakpoint(S) {
        width: 100%;
    }
    svg {
        max-width: 90%;

        @include breakpoint(S) {
            width: 8rem;
            height: auto;
        }
        @include breakpoint(XS) {
            width: 6rem;
        }
    }
}

.footer-content {
    // width: 70%;

    @include breakpoint(S) {
        width: 100%;
    }
    @include breakpoint(XS) {
        width: 100%;
    }
    .footer-menu {
        text-align: center;
        @include breakpoint(XS) {
            width: 100%;
        }
    }
}

.footer-menu {
    margin-bottom: 2rem;
    padding-right: 1rem;

    // Check if browser supports grid (IE doesn't support support queries themselves so it'll ignore everything in here)
    @supports (display: grid) {
        padding-right: 0;
        width: 100%;
    }
    @include breakpoint(XS) {
        padding-right: 0;
        margin-bottom: 1.5rem;
        border-bottom: 0;
    }
    .footer-menu-wrap {
        @include breakpoint(XS-up) {
            display: block !important; // this is in case someone closes it on mobile and goes to a larger screen
        }
        @include breakpoint(XS) {
            // display: none;
            // padding-bottom: 0.5rem;
        }
        a{
            @include overline;
        }
    }
    ul {
        width: 100%;
        display: grid;
        // Make a 2 column grid where the columns have an auto min-width but a max-width of 18rem so they don't stretch ridiculously far
        grid-template-columns: repeat(2, minmax(auto, 16rem));
        column-gap: 2rem;
        row-gap: 1rem;
        padding-right: 1rem;

        @include breakpoint(M) {
            grid-template-columns: repeat(3, minmax(auto, 16rem));
        }
        @include breakpoint(S) {
            grid-template-columns: 100%;
        }
        @include breakpoint(XS) {
            grid-template-columns: repeat(1, minmax(auto, 16rem));
        }

        @supports (display: grid) {
            margin-bottom: 0;
            padding-right: 0;
        }
        li {
            margin-bottom: 1rem;
            padding-right: 1rem;
            text-transform: uppercase;

            @supports (display: grid) {
                margin-bottom: 0;
                padding-right: 0;
            }
            @include breakpoint(XS) {
                margin-bottom: 0;
            }
        }
    }
    a {
        display: block;
        @include color(color, 'body');
        @include size-S;

        @include breakpoint(XS) {
            padding: 0;
        }
        // &:hover {
        //     @include color(color, 'accent-primary');
        // }
    }
}

.mob-footer-menu-toggle {
    width: 100%;

    .button.icon {
        display: none;

        @include breakpoint(XS) {
            display: flex;
        }
    }
    @include breakpoint(XS) {
        @include size-L;

        &.active {
            @include color(color, 'accent-primary');

            .button {
                @include color(border-color, 'accent-primary');

                svg {
                    @include color(color, 'accent-primary');
                }
            }
        }
    }
}

.footer-content-top {
    display: grid;
    // Basically this is saying "if there's enough space, fill in columns at comfortable width with a min-width of 16rem
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    gap: 2rem;

    @include breakpoint(S) {
        margin: auto;
        grid-template-columns: 100%;
    }
}

.footer-content-bottom {
    @include breakpoint(XS) {
        padding-top: 1.5rem;
        text-align: center;
    }
    span {
        margin-right: 2rem;

        @include breakpoint(S) {
            margin: 0 0 1.5rem 0;
            width: 100%;

            &:last-child {
                margin-bottom: 3rem;
            }
        }
    }
    a {
        @include color(color, 'body');

        &:hover {
            @include color(color, 'accent-primary');
        }
    }
}

.footer-reveal{
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 1;
    visibility: visible;
    z-index: -1;
    padding: 3rem 0;
    @include breakpoint(XS) {
        padding: 0rem 3rem;
    }

    @include color(background-color, 'accent-secondary');
    .first-element-footer{
        padding: 1rem 0;
        img{
            margin: auto;
        }
    }
    p{
        font-weight: 700;
        margin-top: 1rem;
        @include color(color, 'background');
    }
    .footer-reveal-logos{
        padding: 3rem 0;
        a{
            padding: 0 1rem;
            // max-width: 140px;
            margin: auto 1rem;
        }
        @include breakpoint(S) {
            flex-direction: column;
            a{
                margin: 1.5rem auto;
                width: 100%;
                max-width: unset;
                img{
                    margin: auto;
                }
            }
        }
    }
    @include breakpoint(M) {

    }
}